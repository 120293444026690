export const state = () => ({
  popularProducts: [],
  productsBySlug: {
    bridge: [],
    deck: [],
    engineering: [],
    galley: [],
    interior: []
  },
  reviewsStatus: null,
  ribbon:{},
  isFetching:"",
  topPromotionalBanner: {
    id: 0,
    image_desktop: {
      thumb:{
        url: "../../assets/images/greybg.jpg"
      }
    },
    image_tablet: {
      thumb:{
        url: "../../assets/images/greybg.jpg"
      }
    },
    image_mobile: {
      thumb:{
        url: "../../assets/images/greybg.jpg"
      }
    },
    url: "javascript(void)",
    name: "loader",
    open_in_new_tab: false
  },
  middlePromotionalBanner: {
    id: 0,
    image_desktop: {
      thumb:{
        url: "../../assets/images/greybg.jpg"
      }
    },
    image_tablet: {
      thumb:{
        url: "../../assets/images/greybg.jpg"
      }
    },
    image_mobile: {
      thumb:{
        url: "../../assets/images/greybg.jpg"
      }
    },
    url: "javascript(void)",
    name: "loader",
    open_in_new_tab: false
  }
});

export const mutations = {
  setPopularProducts(state, popularProducts) {
    state.popularProducts = popularProducts;
  },
  setProductsBySlug(state, { slug, products}) {
    state.productsBySlug[slug] = products;
  },
  setReviewStatus(state, reviewdata){
    state.reviewsStatus = reviewdata;
  },
  updateRibbon(state, ribbon){
    state.ribbon = ribbon;
  },
  updateIsFetching(state, isFetching){
    state.isFetching = isFetching;
  },
  updateTopPromotionalBanner(state, topPromotionalBanner){
    state.topPromotionalBanner = topPromotionalBanner;
  },
  updateMiddlePromotionalBanner(state, middlePromotionalBanner){
    state.middlePromotionalBanner = middlePromotionalBanner;
  }
};

export const getters = {
  productsBySlug( state ) {
    return state.productsBySlug;
  },
};

export const actions = {
  async fetchHomePageData({ commit }, { params }) {
    let popularProductsUrl = 'products/popular?type=home';
    if (params?.port_slug) {
      popularProductsUrl += `&port_slug=${params?.port_slug}`;
    }
    const popularProductsData = await this.$axios.$get(popularProductsUrl);
    commit('setPopularProducts', popularProductsData);
  },

  async fetchHomePageProducts({ commit }, { slug }) {
    commit('updateIsFetching',slug);
    const products = await this.$axios.$get('products/for_home_page', { params: {
      category_slug: slug
    }});
    commit('updateIsFetching',"");
    commit('setProductsBySlug', {
      slug,
      products,
    });
  },

  async fetchPromotionalBannersBySlug({ commit }, { slug }) {
    const promotional_banners = await this.$axios.$get(`promotions/promotional_banners?page_slug=${slug}`);
    if(slug == 'home'){
      const topBanner = promotional_banners.find(({place}) => place === `top`);
      const middleBanner = promotional_banners.find(({place}) => place === `middle`);
      commit('updateTopPromotionalBanner', topBanner);
      commit('updateMiddlePromotionalBanner', middleBanner);
    }
  },

  async showTrustpilotReview({commit}){
    const reviewdata = await this.$axios.$get('products/get_trust_pilot_status')
    commit('setReviewStatus', reviewdata.status)
  }
};
