import onLoad from "~/helpers/onLoad";

export default function () {
    if(process.client){
        let zohoInitialized = false;
        function initialize(){
            if(!zohoInitialized) {
                zohoInitialized = true;
                onLoad(() => {
                    let d = document;
                    let s = d.createElement("script");
                    s.type = "text/javascript";
                    s.id = "zsiqscript";
                    s.src = "https://salesiq.zoho.eu/widget";
                    s.defer = true;
                    d.head.appendChild(s);
                });
                onLoad(() => {
                    let d = document;
                    let s = d.createElement("script");
                    s.type = "text/javascript";
                    s.src = "https://cdn-eu.pagesense.io/js/yachtneeds/78f1f59fd8c743758c7e4d74afb0a5ae.js";
                    s.defer = true;
                    d.head.appendChild(s);
                });
            }
        }
        window.addEventListener("scroll", initialize,{ passive:true });
        window.addEventListener("click", initialize);
        window.addEventListener("dblclick", initialize);
        window.addEventListener("contextmenu", initialize);
    }
}