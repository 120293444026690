import { POPUP_NAMES } from '@/utils/popup-names';

export default class AuthService {

  constructor($auth) {
    this.$auth = $auth;
  }

  onLogout() {
    this.$auth.ctx.app.store.commit('cart/clearCart', null, { root: true });
    localStorage.removeItem('intervalId');
    if (
      this.$auth.ctx.app.router.currentRoute.name === 'checkout' ||
      this.$auth.ctx.app.router.currentRoute.name === 'checkout-overview'
    ) {
      this.$auth.ctx.app.store.commit('modal/setComponent', POPUP_NAMES.LOGIN_TIME_OUT_IN_CART);
      this.$auth.ctx.app.store.commit('shared/saveCallbackAfterLogin', this._goToTheCheckoutPage.bind(this));
      this.$auth.ctx.app.router.push({ 'path': '/' });
    } else {
      this.$auth.ctx.app.store.commit('modal/setComponent', POPUP_NAMES.LOGIN_TIME_OUT_ON_SITE);
    }
  }

  _goToTheCheckoutPage() {
    this.$auth.ctx.app.router.push({ path: '/checkout' });
  }

}
