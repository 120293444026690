export const state = () => ({
  products: null,
  productsCount: null,
  categories: [],
  minPrice: null,
  maxPrice: null,
  minPriceUsd: null,
  maxPriceUsd: null,
  currentCategory: null,
  manufacturers: [],
  parameters: [],
  currentSubcategories: [],
  metaData: [],
  popularCategoriesOnCatalogPage: [],
  portCategoriesData: {},
  popularProducts: [],
});

export const actions = {
  async fetchCategoryData({ commit }, payload) {
    const category = await this.$axios.$get(`/categories/${payload.route.params.slug}`);
    commit('setCurrentCategory', category);
    return category;
  },
  async fetchCategoryBreadcrumbs({ commit }, payload) {
    const categories = await this.$axios.$get(`categories/${payload.route.params.slug}/breadcrumbs`);
    commit('setCategories', categories);
  },
  async fetchCatalogData({ commit, state, rootGetters }, payload) {
    const query = payload.query ? payload.query : payload.route.query;
    query['is_usa_prices'] = rootGetters['shared/isRegionUS'];
    const searchParams = {
      category_id: state.currentCategory.id,
      page: payload.route.query.page,
      sort: query.sort,
      query: query,
      port_slug: payload.route.params.port_slug
    };
    const [productsData, filterData] = await Promise.all([
      this.$axios.$post('products/filter', searchParams),
      this.$axios.$post('products/filter-data', searchParams)
    ]);
    commit('setProducts', productsData.products);
    commit('setProductsCount', productsData.products_count);
    commit('setMinPrice', filterData.min_price);
    commit('setMaxPrice', filterData.max_price);
    commit('setMinPriceUsd', filterData.min_price_usd);
    commit('setMaxPriceUsd', filterData.max_price_usd);
    commit('setParameters', filterData.parameters);
    commit('setManufacturers', filterData.manufacturers);
  },
  async loadMoreCatalogProducts({ commit, state, rootGetters }, payload) {
    const query = payload.query ? payload.query : payload.route.query;
    query['is_usa_prices'] = rootGetters['shared/isRegionUS'];
    const searchParams = {
      category_id: state.currentCategory.id,
      page: payload.page,
      sort: query.sort,
      query: query,
      port_slug: payload.route.params.port_slug
    };
    const productsData = await this.$axios.$post('products/filter', searchParams);
    commit('addProducts', productsData.products);
  },
  async fetchFilterData({ commit, state, rootGetters }, payload) {
    const query = payload.query ? payload.query : payload.route.query;
    query['is_usa_prices'] = rootGetters['shared/isRegionUS'];
    const searchParams = {
      category_id: state.currentCategory.id,
      query: query,
      port_slug: payload.route.params.port_slug
    };
    const filterData = await this.$axios.$post('products/filter-data', searchParams);
    commit('setMinPrice', filterData.min_price);
    commit('setMaxPrice', filterData.max_price);
    commit('setMinPriceUsd', filterData.min_price_usd);
    commit('setMaxPriceUsd', filterData.max_price_usd);
    commit('setParameters', filterData.parameters);
    commit('setManufacturers', filterData.manufacturers);
  },

  async loadRootCategoryChildren({ commit }, payload) {
    await this.$axios.$get(`categories/root-category-children/${payload.slug}`).then(async (data) => {
      commit('setCurrentSubcategories', data.subcategories);
    }).catch((e) => {
      payload.error({ statusCode: e.status, message: e.statusText });
    });
  },
  async loadPopularProducts({ commit }, { slug, params, type }) {
    let popularProductsUrl = `products/popular?category_slug=${slug}`;
    if (params?.port_slug) {
      popularProductsUrl += `&port_slug=${params?.port_slug}`;
    }
    if (type) {
      popularProductsUrl += `&type=${type}`;
    }
    await this.$axios.$get(popularProductsUrl).then(function(data) {
      commit('setPopularProducts', data);
    });
  },
  async loadPopularCategoriesOnCatalogPage({ commit }, payload) {
    let additionalQuery = '';
    if (payload.elementsCount) {
      additionalQuery = '&limit=' + payload.elementsCount;
    }
    await this.$axios.$get(`categories/popular-categories-on-catalog-page?slug=${payload.slug}${additionalQuery}`)
      .then((setPopularCategoriesOnCatalogPage) => {
      commit('setPopularCategoriesOnCatalogPage', setPopularCategoriesOnCatalogPage);
    }).catch((e) => {
      console.log('error here', e);
    });
  }
};

export const mutations = {
  setProducts(state, products) {
    state.products = products;
  },
  addProducts(state, products) {
    state.products = state.products.concat(products);
  },
  setProductsCount(state, productsCount) {
    state.productsCount = productsCount;
  },
  setMinPrice(state, minPrice) {
    state.minPrice = minPrice;
  },
  setMaxPrice(state, maxPrice) {
    state.maxPrice = maxPrice;
  },
  setMinPriceUsd(state, minPriceUsd) {
    state.minPriceUsd = minPriceUsd;
  },
  setMaxPriceUsd(state, maxPriceUsd) {
    state.maxPriceUsd = maxPriceUsd;
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
  setCurrentCategory(state, currentCategory) {
    state.currentCategory = currentCategory;
  },
  setManufacturers(state, manufacturers) {
    state.manufacturers = manufacturers;
  },
  setParameters(state, parameters) {
    state.parameters = parameters;
  },
  setMetaData(state, metaData) {
    state.metaData = metaData;
  },
  setPortCategoriesData(state, portCategoriesData) {
    state.portCategoriesData = portCategoriesData;
  },
  setPopularCategoriesOnCatalogPage(state, popularCategoriesOnCatalogPage) {
    state.popularCategoriesOnCatalogPage = popularCategoriesOnCatalogPage;
  },
  setCurrentSubcategories(state, subcategories) {
    state.currentSubcategories = subcategories;
  },
  setPopularProducts(state, popularProducts) {
    state.popularProducts = popularProducts;
  }
};
