import Vue from 'vue';

export function formatNumber(number) {
  return (Math.round(number * 100) / 100).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const filters = { formatNumber };

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
});

export default filters
