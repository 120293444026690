import { TYPES } from '@/utils/types/sort-type-catalog';

export const state = () => ({
  products: [],
  productsCount: 0,
  manufacturers: [],
  manufacturersCount: 0,
  manufacturer: null,
});

export const mutations = {
  setManufacturers(state, manufacturers) {
    state.manufacturers = manufacturers;
  },
  setManufacturersCount(state, count) {
    state.manufacturersCount = count;
  },
  setProducts(state, products) {
    state.products = products;
  },
  setProductsCount(state, productsCount) {
    state.productsCount = productsCount;
  },
  setManufacturer(state, manufacturer) {
    state.manufacturer = manufacturer;
  }
};

export const getters =  {
  products(state) {
    return state.products;
  },
  productsCount(state) {
    return state.productsCount;
  },
  manufacturer(state) {
    return state.manufacturer;
  },
  manufacturers(state) {
    return state.manufacturers;
  },
  manufacturersCount(state) {
    return state.manufacturersCount;
  }
};

export const actions = {
  async fetchManufacturer({ commit }, { slug }) {
    const manufacturer = await this.$axios.$get(`brands/${slug}`);
    commit('setManufacturer', manufacturer);
    return manufacturer;
  },
  async fetchManufacturersList({ commit }) {
    const manufacturersData = await this.$axios.$get('brands');
    commit('setManufacturers', manufacturersData.manufacturers);
    commit('setManufacturersCount', manufacturersData.manufacturers_count);

  },
  async fetchManufacturerProducts({ commit, rootGetters }, { manufacturerName, page, sort }) {
    const query = { is_usa_prices: rootGetters['shared/isRegionUS'], brand: manufacturerName }
    const searchParams = { page, sort, query };
    const productsData = await this.$axios.$post('products/filter', searchParams);
    commit('setProducts', productsData.products);
    commit('setProductsCount', productsData.products_count);
  }
};
