import onLoad from "~/helpers/onLoad";

export default function(){
    let iubendaInitialised = false;
    function initialise(){
        if(!iubendaInitialised){
            iubendaInitialised = true;
            onLoad(() => {
                let d = document, script = d.createElement("script");
                script.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
                script.defer = true;
                d.head.appendChild(script);
            });
        }
    }

    window.addEventListener("scroll", initialise,{ passive:true });
    window.addEventListener("click", initialise);
    window.addEventListener("contextmenu", initialise);
}