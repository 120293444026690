import AuthService from '@/services/auth-service';

export default function ({ $axios, app }) {

  window.onfocus = () => {
    if (app.$auth.loggedIn) {
      $axios.$get('session/user').then((res) => {
        app.store.commit('cart/updateHasWaterLimit', res.user.has_water_limit);
      }).catch(() => {
        app.$auth.reset().then(() => {
          new AuthService(app.$auth).onLogout();
        });
      });
    }
    // app.store.dispatch('cart/loadOrderItems', null, { root: true })
  };

};
