import onLoad from "~/helpers/onLoad";

export default ({ app }, inject) => {
    if(process.client){
        let matomoInitialized = false;
        function initialize(){
            if(matomoInitialized) return;
            matomoInitialized = true;
            onLoad(() => {
                let u="//analytics.yachtneeds.com/";
                let d=document, g=d.createElement('script');
                g.defer=true;
                g.src=u+'matomo.js';
                d.head.appendChild(g);
            });
        }
        window.addEventListener("scroll", initialize,{ passive:true });
        window.addEventListener("click", initialize);
        window.addEventListener("dblclick", initialize);
        window.addEventListener("contextmenu", initialize);
    }
};