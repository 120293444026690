export const state = () => ({
  product: null,
  supplierCards: [],
  parameters: [],
  selectedQuotationSupplierId: null,
  supplierName: null,
  categories: null,
  masterSpecifications: null,
  allVariants: null,
  shouldFetchProduct: true,
  minPrice:0
});

export const mutations = {
  updateProduct(state, product) {
    state.product = product;
  },
  updateSupplierCards(state, supplierCards) {
    state.supplierCards = supplierCards;
  },
  updateParameters(state, parameters) {
    state.parameters = parameters;
  },
  updateSelectedQuotationSupplierId(state, supplierId) {
    state.selectedQuotationSupplierId = supplierId;
  },
  updateSupplierName(state, supplierName){
    state.supplierName = supplierName;
  },
  updateAllCategories(state, categories){
    state.categories = categories
  },
  updateMasterSpecifications(state, spec){
    state.masterSpecifications = spec;
  },
  updateAllVariants(state, variants){
    state.allVariants = variants;
  },
  updateShouldFetchProduct(state, shouldFetchProduct){
    state.shouldFetchProduct = shouldFetchProduct;
  },
  updateMinPrice(state, minPrice){
    state.minPrice = minPrice;
  }
};

export const actions = {
  async fetchProduct({commit}, payload) {
    commit('shared/startLoading', null, { root: true });
    const { promotedBy, slug, portSlug } = payload;
    await this.$axios.$get(`products/${slug}`, { params: { promoted_by: promotedBy} }).then(async (product) => {
      commit('updateProduct', product);
      let [supplierCards, parameters] = await Promise.all([
        this.$axios.$get(`products/${product.id}/supplier_cards`, { params: { port_slug: portSlug, promoted_by: promotedBy }}),
        this.$axios.$get(`products/${product.id}/parameters`).then((data) => {
          let myMap = new Map();
          // if (product.manufacturer) {
          //   myMap.set('product.manufacturer', { name: 'Manufacturer', value: product.manufacturer });
          // }
          if(product.is_deleted) this.$nuxt.error({ statusCode: 400, message: 'Error from API: Flag is true' });
          if(product.brand){
            myMap.set('product.brand', { name: 'Brand', value: product.brand });
          }
          if (product.ean) {
            myMap.set('ean/barcode', { name: 'Ean/Barcode', value: product.ean });
          }
          data.forEach((element) => {
            let value;
            if (myMap.get(element.parameter_id)) {
              value = myMap.get(element.parameter_id).value + ', ' + element.value;
            } else {
              value = element.value;
            }
            myMap.set(element.parameter_id, { name: element.name, value: value });
          });
          return Array.from(myMap.values());
        })
      ]);
      commit('updateSupplierName', product.min_price_supplier)
      commit('updateSupplierCards', supplierCards);
      commit('updateParameters', parameters);
      commit('updateAllCategories', product.categories);
      commit('updateAllVariants', product.all_product_variants);
      commit('updateMasterSpecifications', product.master_specification_detail);
    }).catch((e) => {
      payload.error({ statusCode: e.status, message: e.statusText });
    }).finally(() => {
      commit('shared/endLoading', null, { root: true });
    });
  },
};
