import { REGIONS } from '@/utils/types/region';

export const state = () => ({
  callbackAfterLogin: null,
  loadingsCount: 0,
  isLoading: false,
  registeredEmail: '',
  isShownChangeEmailButton: true,
  siteRegion: '',
  siteLocation:'',
  siteFlag:'',
  siteLang: '',
  categories: [],
  categoriesAreLoading: false,
});

const filterShownCategoriesTree = (categories) => {
  return categories.reduce((acc, category) => {
    if (category.shown_on_preview == true) {
      const { subcategories, ...rest } = category;
      let filteredSubcategories = []
      if (subcategories && subcategories.length != 0) {
        filteredSubcategories = filterShownCategoriesTree([...subcategories])
      }
      acc.push({ ...rest, subcategories: filteredSubcategories })
    }
    return acc;
  }, [])
};

export const getters = {
  isRegionUS(state) {
    return state.siteRegion === REGIONS.US;
  },
  shownCategories(state) {
    return filterShownCategoriesTree(state.categories);
  },
  categoriesTree(state) {
    return state.categories;
  }
};

export const mutations = {
  saveCallbackAfterLogin(state, callback) {
    state.callbackAfterLogin = callback;
  },
  saveRegisteredEmail(state, registeredEmail) {
    state.registeredEmail = registeredEmail;
  },
  hideChangeEmailButton(state) {
    state.isShownChangeEmailButton = false;
  },
  callbackAfterLogin(state) {
    if (state.callbackAfterLogin) {
      state.callbackAfterLogin();
      this.commit('shared/clearCallbackAfterLogin');
    }
  },
  clearCallbackAfterLogin(state) {
    state.callbackAfterLogin = null;
  },
  startLoading(state) {
    state.loadingsCount++;
    if (state.loadingsCount > 0) {
      state.isLoading = true;
    }
  },
  endLoading(state) {
    state.loadingsCount--;
    if (state.loadingsCount <= 0) {
      state.isLoading = false;
    }
  },
  unsetComponent(state) {
    state.false = true;
  },
  initSiteRegion(state, siteRegion) {
    this.commit('shared/setSiteRegion', siteRegion ? siteRegion : REGIONS.EU, { root: true });
  },
  initSiteLocation(state, siteLocation){
    this.commit('shared/setSiteLocation', siteLocation ? siteLocation : '', { root: true });
  },
  initSiteFlag(state, siteFlag){
    this.commit('shared/setSiteFlag', siteFlag ? siteFlag : '', { root: true });
  },
  initSiteLang(state, siteLang){
    this.commit('shared/setSiteLang', siteLang ? siteLang : 'en', { root: true });
  },
  setSiteLocation(state, siteLocation) {
    state.siteLocation = siteLocation;
    this.$cookies.set('siteLocation', siteLocation, { maxAge: 3600000 });
  },
  setSiteFlag(state, siteFlag) {
    state.siteFlag = siteFlag;
    this.$cookies.set('siteFlag', siteFlag, { maxAge: 3600000 });
  },
  setSiteRegion(state, siteRegion) {
    state.siteRegion = siteRegion;
    this.$cookies.set('siteRegion', siteRegion, { maxAge: 3600000 });
  },
  setSiteLang(state, siteLang){
    state.siteLang = siteLang;
    this.$cookies.set('siteStateLang', siteLang, { maxAge: 3600000 });
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
  setCategoriesAreLoading(state, flag) {
    state.categoriesAreLoading = flag;
  }
};

export const actions = {
  async fetchCategories({ state, commit }) {
    if (!state.categoriesAreLoading && state.categories.length == 0) {
      commit('setCategoriesAreLoading', true)
      const data = await this.$axios.$get('categories');
      commit('setCategories', data);
      commit('setCategoriesAreLoading', false)
    }
  }
}