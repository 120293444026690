export const state = () => ({
  dfpSlots: {},
  isEnabledGoogleTag: false
});

export const mutations = {
  addDfpSlot(state, { id, slot }) {
    state.dfpSlots[id] = slot;
  },
  enableGoogleTag(state) {
    state.isEnabledGoogleTag = true;
  }
};
