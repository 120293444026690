export const state = () => ({
  portData: '',
  portSeoData: '',
  portCategoriesData: {}
});

export const mutations = {
  setPortData(state, portData) {
    state.portData = portData;
  },
  setPortSeoData(state, portSeoData) {
    state.portSeoData = portSeoData;
  },
  setPortCategoriesData(state, portCategoriesData) {
    state.portCategoriesData = portCategoriesData;
  }
};

export const actions = {
  async loadPortData({ commit }, payload) {
    await this.$axios.$get(`locations/ports/port-info/${payload.slug}`).then(async (data) => {
      commit('setPortData', data);
    }).catch((e) => {
      payload.error({ statusCode: e.status, message: e.statusText });
    });
  },
  async loadPortSeoData({ commit }, payload) {
    await this.$axios.$get(`locations/ports/port-seo-info/${payload.slug}`).then(async (data) => {
      commit('setPortSeoData', data);
    }).catch((e) => {
      payload.error({ statusCode: e.status, message: e.statusText });
    });
  },
  async loadPortCategories({ commit }, payload) {
    const categorySlug = payload.category_slug ? '?category_slug=' + payload.category_slug : '';
    await this.$axios.$get(`categories/for-port/${payload.slug}${categorySlug}`).then((data) => {
      commit('setPortCategoriesData', data);
    }).catch((e) => {
      payload.error({ statusCode: e.status, message: e.statusText });
    });
  }
};
