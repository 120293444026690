export const ERROR_TYPES = {
  NOT_FOUND: 1,
  LOGIN_TIME_OUT: 2,
  EMAIL_ALREADY_USED: 3,
  UNNEEDED_REFRESH_TOKEN: 4,
  INVALID_CATEGORY: 5,
  MANY_EMAIL_ACTIONS: 6,
  WRONG_USER_CODE: 7,
  CONFIRM_EXPIRED: 8,
  EMAIL_NOT_APPROVED: 9,
  UPS_DELIVERY_UNAVAILABLE: 10,
  USER_DEACTIVATED: 11,
  PAYMENT_SYSTEM_ERROR: 12,
  SHIPPY_PRO_ERROR: 13,
  WRONG_REGION_CODE: 14,
  EMAIL_IS_GUEST: 15
};
