export const state = () => ({
  currentUrl: '',
  currentLanguage: 'en' // Initial language set to 'en'
});

export const mutations = {
  setCurrentUrl(state, url) {
    state.currentUrl = url;
  },
  setCurrentLanguage(state, language) {
    state.currentLanguage = language;
  }
}

export const actions = {
  async nuxtServerInit({ commit, dispatch }, { route }) {
    commit('setCurrentUrl', `${process.env.FULL_DOMAIN}${route.path}`);
    commit('shared/initSiteRegion', this.$cookies.get('siteRegion'), { root: true });
    commit('shared/initSiteLocation', this.$cookies.get('siteLocation'), { root: true });
    commit('shared/initSiteFlag', this.$cookies.get('siteFlag'), { root: true });
    commit('shared/initSiteLang', this.$cookies.get('siteStateLang'), { root: true});
    await dispatch('shared/fetchCategories');
    await dispatch('home/showTrustpilotReview');
  }
};