import { PAGE_TYPES } from '@/utils/types/page';

export const state = () => ({
  companyPages: [],
  legalInfoPages: []
});

export const mutations = {
  setCompanyPages(state, companyPages) {
    state.companyPages = companyPages;
  },
  setLegalInfoPages(state, legalInfoPages) {
    state.legalInfoPages = legalInfoPages;
  }
};

export const actions = {
  async fetchFooterData({ commit }) {
    const pagesData = await this.$axios.$get('pages');
    commit('setCompanyPages', pagesData.filter(pagesData => pagesData.type === PAGE_TYPES.COMPANY));
    commit('setLegalInfoPages', pagesData.filter(pagesData => pagesData.type === PAGE_TYPES.LEGAL_INFO));
  }
};
