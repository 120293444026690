import { TYPES } from '@/utils/types/sort-type-catalog';

export const state = () => ({
  products: [],
  productsCount: 0,
  popularProducts: null,
});

export const getters =  {
  popularProducts(state) {
    return state.popularProducts;
  },
  products(state) {
    return state.products;
  }
};

export const mutations = {
  updateProducts(state, products) {
    state.products = products;
  },
  updateProductsCount(state, productsCount) {
    state.productsCount = productsCount;
  },
  setPopularProducts(state, popularProducts) {
    state.popularProducts = popularProducts;
  }
};

export const actions = {
  async fetchProducts({ commit }, payload) {
    const query = payload.query ? payload.query : payload.route.query;
    const port = payload.params?.port_slug ? '&port_slug=' + payload.params.port_slug : '';
    const page = payload.route.query.page ? '&page=' + payload.route.query.page : '';
    const sort = query.sort && query.sort !== TYPES.BY_AVAILABILITY ? '&sort=' + query.sort : '';
    const data = await this.$axios.$get('products/search?q=' + encodeURIComponent(query.query) + page + sort + port);
    commit('updateProducts', data.products);
    commit('updateProductsCount', data.products_count);
  },

  async fetchPopularProducts({ commit }) {
    const popularProductsData = await this.$axios.$get('products/popular?type=home');
    commit('setPopularProducts', popularProductsData);
  },
  async fetchUrlProducts({ commit }, searchTerm){
    const query = searchTerm.query ? searchTerm.query : searchTerm.route.query;
    let obj = Object.fromEntries(Object.entries(query).filter(([_, v]) => v != 'null'));
    this.url = Object.keys(obj).reduce(function (a, k) { a.push(k + '=' + encodeURIComponent(obj[k])); return a }, []).join('&')
    const data = await this.$axios.$get('products/search_products?' + this.url);
    commit('updateProducts', data.products);
    commit('updateProductsCount', data.total_products_count);
  }
};
