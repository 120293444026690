import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// export default ({ app }) => {
//   app.i18n = new VueI18n({
//     locale: 'en',
//     fallbackLocale: 'en',
//     messages: {
//       en: require('@/locales/en.json'),
//       fr: require('@/locales/fr.json')
//     }
//   })
// }

export default ({ app }) => {
  // Retrieve the language from cookies or default to 'en'
  const locale = app.$cookies.get('language') ? app.$cookies.get('language') : 'en';

  app.i18n = new VueI18n({
    locale,
    fallbackLocale: 'en',
    messages: {
      en: require('@/locales/en.json'),
      fr: require('@/locales/fr.json'),
      it: require('@/locales/it.json'),
      tr: require('@/locales/tk.json'),
      el: require('@/locales/gr.json'),
      es: require('@/locales/sp.json')
    }
  })
}