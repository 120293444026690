export const POPUP_NAMES = {
  REGISTRATION_POPUP: 'RegistrationPopup',
  LOGIN_POPUP: 'LoginPopup',
  RESET_PASSWORD_POPUP_1: 'ResetPasswordPopup1',
  RESET_PASSWORD_POPUP_2: 'ResetPasswordPopup2',
  RESET_PASSWORD_POPUP_3: 'ResetPasswordPopup3',
  RESET_PASSWORD_POPUP_4: 'ResetPasswordPopup4',
  SHOPPING_CART_POPUP: 'ShoppingCart',
  EMPTY_SHOPPING_CART_POPUP: 'EmptyShoppingCart',
  LOGIN_TIME_OUT_IN_CART: 'LoginTimeOutInCart',
  LOGIN_TIME_OUT_ON_SITE: 'LoginTimeOutOnSite',
  QUOTATION_POPUP: 'QuotationPopup',
  QUOTATION_SUCCESS_POPUP: 'QuotationSuccessPopup',
  BASE_SIDEBAR: 'BaseSidebar',
  EMAIL_ALREADY_USED: 'EmailAlreadyUsed',
  EMAIL_CONFIRMATION_WAS_SENT: 'EmailConfirmationWasSent',
  DATA_SUCCESSFULLY_UPDATED: 'DataSuccessfullyUpdated',
  UPS_ACCESS_POINTS_MAP: 'UpsAccessPointsMap',
  CHECK_EMAIL_POPUP: 'CheckEmailPopup',
  LINK_IS_EXPIRED_POPUP: 'LinkIsExpiredPopup',
  WRONG_CONFIRMATION_CODE_EMAIL: 'WrongConfirmationCodeEmail',
  CONFIRM_LINK_EXPIRED: 'ConfirmLinkExpired',
  TOO_MANY_ACTIONS: 'TooManyActions',
  TOO_LARGE_ORDER: 'TooLargeOrder',
  GUEST_LOGIN: 'GuestPopup',
  PASSWORD_WAS_SENT: 'PasswordWasSent',
  SAVED_ADDRESS: "SavedAddress",
  ADD_NEW_ADDRESS: "AddNewAddress"
};
