export default function ({$gtm,route}) {
    // $gtm.init(process.env.gtmId)
    if(process.client){
        let gtmInitialized = false;
        function initialize(){
            if (!gtmInitialized) {
                gtmInitialized = true;
                $gtm.init(process.env.GTM_ID);
            }
        }
        window.addEventListener("scroll", initialize, { passive:true });
        window.addEventListener("click", initialize);
        window.addEventListener("contextmenu", initialize);
    }
}