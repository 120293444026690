import isEmpty from 'lodash/isEmpty';
import Vue from 'vue';
import { ValidationProvider, extend, ValidationObserver, setInteractionMode } from 'vee-validate';
import * as VeeValidate from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { required, email, min, max } from 'vee-validate/dist/rules';
import { UPS_ERRORS } from '@/utils/types/ups-error';

// Now tell vue to use this plugin

Vue.use(VeeValidate, { inject: false });
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

setInteractionMode('custom', function(data) {
  if (data.errors.length) {
    return { on: ['input'] };
  }
  return { on: [] };
});

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('required-state-province', {
  ...required,
  message: 'This field is required. If not applicable, enter your City.'
});

extend('validId', {
  params: ['id'],
  validate(value, { id }) {
    return id > 0;
  },
  message: 'Please, enter correct value'
});

extend('email', {
  ...email,
  message: 'You must fill out a valid email'
});

extend('min', {
  ...min,
  message: 'Length of the {_field_} has to be minimum {length}.'
});

extend('max', {
  ...max,
  message: 'This field may not be greater than {length} characters.'
});

extend('passwordStrength', {
  validate(value) {
    return value.length >= 8 && /\d/.test(value) && /[A-Z]/.test(value) && /[a-z]/.test(value);
  },
  message: 'At least 8 symbols, including capitals and numbers'
});

extend('onlyLetters', {
  validate(value) {
    return /^[a-zA-Z ]+$/.test(value);
  },
  message: 'You must fill out a latin letters'
});

extend('onlyKeyLetters', {
  validate(value) {
    return /^[a-zA-Z0-9"'`_.+\-@, \p{L}\p{M}’]+$/u.test(value);
  },
  message: 'Invalid format - please remove special characters'
});
extend
extend('validCity', {
  params: ['error'],
  validate(value, { error }) {
    if (isEmpty(error)) {
      return true;
    } else {
      return error.errorCode !== UPS_ERRORS.INVALID_DESTINATION_CITY;
    }
  },
  message: ' '
});
