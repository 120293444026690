import Vue from 'vue';
import { globalBus } from '@/services/global-bus'

import { AVAILABILITY_TYPES } from '@/utils/types/availability';
import { POPUP_NAMES } from '@/utils/popup-names';
import { ERROR_TYPES } from '@/utils/types/error';
import { LIMITS } from '@/utils/limits.js'
import { SUPPLIER_PICK_UP_LOCATION_ID } from '@/utils/types/order-delivery-by';
import { ORDER_DELIVERY_DESTINATION_TYPES } from '@/utils/types/order-delivery-destination';

export const state = () => ({
  products: [],
  ports: [],
  supplierOrders: [],
  currentAction: '',
  deliveryInfo: {},
  stripeStatus: true,
  voucherDiscount: {},
  voucherError: false,
  exchange_rate_EUR_to_USD: '',
  applied_coupon: '',
  selected_coupon: '',
  savedAddresses: [],
  currentAddress: null,
  editSavedAddress: false,
  addressToBeEdited: null,
  addressList: null,
  selectedAddress: -1,
  savedAddressSupplierOrder: null,
  addNewAddressError: null,
  watersCategoryQuantity: 0,
  has_water_limit: true
});

export const getters = {
  productsInCartCount(state) {
    return state.products.length;
  },
  totalSum(state) {
    if (state.products.length) {
      return state.products.map(product => product.price * product.quantity).reduce((prev, next) => prev + next);
    }
    return 0;
  },
  totalSumUsd(state) {
    if (state.products.length) {
      return state.products.map(product => product.price_usd * product.quantity).reduce((prev, next) => prev + next);
    }
    return 0;
  }
};

export const mutations = {
  setStripeStatus(state, reviewdata) {
    state.stripeStatus = reviewdata;
  },

  setProductsFromBackEnd(state, orderItems) {
    state.products = [];
    orderItems.forEach((orderItem) => {
      this.commit('cart/addToCartProductSupplierCard', {
        product: orderItem.product,
        slug: orderItem.slug,
        supplierCard: orderItem.supplier_card,
        quantity: orderItem.quantity,
        pictureUrl: orderItem.picture.url,
        supplierOrderId: orderItem.supplier_order_id,
        upsDeliveryAvailability: orderItem.ups_delivery_availability
      })
    });
  },

  addToCartProductSupplierCard(state, { product, slug, supplierCard, quantity, pictureUrl, supplierOrderId, upsDeliveryAvailability }) {
    state.products.unshift({
      id: product.id,
      name: product.name,
      slug: slug,
      picture: { url: pictureUrl },
      quantity: quantity,
      supplier_card_id: supplierCard.id,
      price: supplierCard.price,
      price_usd: supplierCard.price_usd,
      days_for_delivery: supplierCard.days_for_delivery,
      availability_type: supplierCard.availability_type,
      supplierOrderId: supplierOrderId,
      upsDeliveryAvailability,
      product: product
    });
  },

  pushProductToCart(state, { product, supplierCard }) {
    let pictureUrl = '';
    if (product.gallery_images && product.gallery_images.length > 0) {
      pictureUrl = product.gallery_images[0].big_image.url;
    } else if (product.picture.url) {
      pictureUrl = product.picture.url;
    }
    this.commit('cart/addToCartProductSupplierCard', { product, slug: product.slug, supplierCard, quantity: 1, pictureUrl })
  },

  addProductToCart(state, { product, supplierCard, reference_id }) {
    const cartItem = state.products.find(item => item.id === product.id && item.supplier_card_id === supplierCard.id);
    if (cartItem) { cartItem["reference_id"] = reference_id; }
    if (!cartItem) {
      this.commit('cart/pushProductToCart', { product, supplierCard, reference_id });
    } else {
      this.commit('cart/changeProductInventory', { cartItem: cartItem, newQuantity: cartItem.quantity + 1, reference_id: reference_id });
    }
    this.dispatch('cart/saveOrder', { cartItem });
  },

  productButtonPreviewAction(state, { product }) {
    if (product && product.suppliers_availablity_type === 1) {
      const supplierCard = product.product_supplier_cards.find(item => item.availability_type === AVAILABILITY_TYPES.IN_STOCK);
      this.commit('cart/addToCartSupplierCard', { product: product, supplierCard });
    } else if (product && product.suppliers_availablity_type === 2) {
      const supplierCard = product.product_supplier_cards.find(item => item.availability_type === AVAILABILITY_TYPES.PICKED_UP_IN_DAYS);
      this.commit('cart/addToCartSupplierCard', { product: product, supplierCard });
    } else {
      this.$router.push(this._vm.$transformParamsForPort($nuxt._route, {
        name: 'product-slug', params: { slug: product.slug }, query: { scroll_to: 'supplierCards' }
      }));
    }
  },

  productButtonLinkAction(state, { product }) {
    this.$router.push(this._vm.$transformParamsForPort($nuxt._route, {
      name: 'product-slug',
      params: { slug: product.slug }
    }));
  },

  changeProductInventory(state, { cartItem, newQuantity }) {
    let flag = false;
    const index = cartItem?.product?.categories?.findIndex(el => el.name === "Waters");
    flag = cartItem?.product?.category_name == "Waters" || index >= 0;
    const limit = LIMITS.WATERS_CATEGORY_LIMIT;
    let total = state.watersCategoryQuantity;
    if (flag && total > limit && state.has_water_limit) {
      this.commit('modal/setComponent', POPUP_NAMES.TOO_LARGE_ORDER);
    } else if (newQuantity >= 1) {
      cartItem.quantity = parseInt(newQuantity);
      this.dispatch('cart/saveOrder', { cartItem });
    }
  },

  addToCartSupplierCard(state, { product, supplierCard, reference_id }) {
    if (state.products.length) {
      let total = 0;
      state.products.forEach(el => {
        console.log("el?.product", el?.product);
        if (el?.product?.category_name == "Waters" || (Array.isArray(el?.product?.categories) && el?.product?.categories[2].name == "Waters")) {
          total += el.quantity;
        }
      });
      this.commit('cart/updateWatersCategoryQuantity', total);
      let flag = false;
      if (product?.categories) {
        const index = product?.categories?.findIndex(el => el.name === "Waters");
        flag = index >= 0 ? true : false;
      } else {
        flag = product?.category_name?.toLowerCase() == "waters" ? true : false;
      }
      const limit = LIMITS.WATERS_CATEGORY_LIMIT;
      if (flag && (total + 1 > limit) && state.has_water_limit) {
        this.commit('modal/setComponent', POPUP_NAMES.TOO_LARGE_ORDER);
        return;
      }
    }
    this.commit('cart/addProductToCart', { product, supplierCard, reference_id });
    this.commit('modal/setComponent', POPUP_NAMES.SHOPPING_CART_POPUP);
  },

  deleteProductFromCart(state, { cartItem, needRedirect }) {
    const index = state.products.findIndex((product) => product.supplier_card_id === cartItem.supplier_card_id);
    state.products.splice(index, 1);
    if (state.products.length === 0) {
      this.commit('modal/setComponent', POPUP_NAMES.EMPTY_SHOPPING_CART_POPUP);
      if (needRedirect) {
        this.$router.push(this._vm.$transformParamsForPort($nuxt._route, { name: 'catalog-slug', params: { slug: 'engineering' } }));
      }
    }
    const productsWithSupplierOrder = state.products.find(item => item.supplierOrderId === cartItem.supplierOrderId);
    if (!productsWithSupplierOrder) {
      const index = state.supplierOrders.findIndex((supplierOrder) => supplierOrder.id === cartItem.supplierOrderId);
      state.supplierOrders.splice(index, 1);
    }
    this.dispatch('cart/saveOrder', { cartItem });
  },

  clearCart(state) {
    state.products = [];
    localStorage.removeItem('orderId');
  },

  setPorts(state, ports) {
    state.ports = ports;
  },

  setSupplierOrders(state, supplierOrders) {
    state.supplierOrders = supplierOrders;
  },

  // todo maybe split cart store into two: order and supplier order
  setSupplierOrderData(state, data) {
    Object.assign(data.supplierOrder, data.data)
  },

  resetAddressData(state, { supplierOrder }) {
    supplierOrder.address_data = {
      address: '',
      components: [],
      locations: {}
    };
    supplierOrder.port_id = null;
    supplierOrder.pick_up_location_data = null;
    Vue.set(state.deliveryInfo, supplierOrder.id, null);
  },

  setCurrentAction(state, currentAction) {
    state.currentAction = currentAction;
  },

  setDeliveryInfo(state, { supplierOrder, deliveryInfo }) {
    Vue.set(state.deliveryInfo, supplierOrder.id, deliveryInfo);
  },

  duplicateDeliveryData(state, { from, to }) {
    Object.assign(to, {
      delivery_destination_type: from.delivery_destination_type,
      company_vessel_name: from.company_vessel_name,
      address_data: from.address_data,
      port_id: from.port_id,
      berth_number: from.berth_number,
      receiver: from.receiver,
      additional_info: from.additional_info,
      pick_up_location_data: from.pick_up_location_data
    });
    globalBus.$emit('supplier_order:duplicated', to.id); // todo do without global bus
  },

  duplicateDeliveryDataPartially(state, { from, to }) {
    Vue.set(to, 'hasPartialDuplicationError', true);
    const duplicatedData = {
      delivery_destination_type: from.delivery_destination_type,
      company_vessel_name: from.company_vessel_name,
      receiver: from.receiver,
      additional_info: from.additional_info,
      address_data: from.address_data
    };
    if (from.pick_up_location_data) {
      if (from.pick_up_location_data.id === SUPPLIER_PICK_UP_LOCATION_ID) {
        to.pick_up_location_data = null;
      }
    } else {
      to.pick_up_location_data = null;
    }
    if (from.delivery_destination_type === ORDER_DELIVERY_DESTINATION_TYPES.PICK_UP) {
      to.pick_up_location_data = null;
    }
    Object.assign(to, duplicatedData);
    globalBus.$emit('supplier_order:duplicated', to.id); // todo do without global bus
  },

  setReceiver(state, { supplierOrder, receiver }) {
    if (receiver) {
      supplierOrder.receiver = { ...receiver };
    } else {
      supplierOrder.receiver = null;
    }
  },
  updateVoucherDiscount(state, voucherDiscount) {
    state.voucherDiscount = voucherDiscount;
  },
  updateVoucherError(state, voucherError) {
    state.voucherError = voucherError;
  },

  update_exchange_rate_EUR_to_USD(state, exchange_rate_EUR_to_USD) {
    state.exchange_rate_EUR_to_USD = exchange_rate_EUR_to_USD;
  },

  updateAppliedCoupon(state, applied_coupon) {
    state.applied_coupon = applied_coupon;
  },

  updateSelectedCoupon(state, selected_coupon) {
    state.selected_coupon = selected_coupon;
  },

  updateSavedAddresses(state, savedAddresses) {
    state.savedAddresses = savedAddresses;
  },

  updateCurrentAddress(state, currentAddress) {
    state.currentAddress = currentAddress;
  },

  updateAddressToBeEdited(state, addressToBeEdited) {
    state.addressToBeEdited = addressToBeEdited;
  },

  updateEditSavedAddress(state, editSavedAddress) {
    state.editSavedAddress = editSavedAddress;
  },

  updateAddressList(state, addressList) {
    state.addressList = addressList;
  },

  updateSelectedAddress(state, selectedAddress) {
    state.selectedAddress = selectedAddress;
  },

  updateSavedAddressSupplierOrder(state, savedAddressSupplierOrder) {
    state.savedAddressSupplierOrder = savedAddressSupplierOrder;
  },

  updateAddNewAddressError(state, addNewAddressError) {
    state.addNewAddressError = addNewAddressError;
  },

  updateWatersCategoryQuantity(state, watersCategoryQuantity) {
    state.watersCategoryQuantity = watersCategoryQuantity;
  },

  updateHasWaterLimit(state, has_water_limit) {
    state.has_water_limit = has_water_limit;
  }
};

export const actions = {
  loadOrderItems({ commit }) {
    if ($nuxt._route.name == 'checkout-status' && $nuxt._route?.query?.payment === 'success') return;
    const orderId = localStorage.getItem('orderId');
    if (orderId) {
      this.$axios.get(`orders/${orderId}`).then((result) => {
        let total = 0;
        result.data.order_items.forEach(el => {
          if (el?.product?.category_name == "Waters" || (Array.isArray(el?.product?.categories) && el?.product?.categories[2].name == "Waters")) {
            total += el.quantity;
          }
        });
        commit('updateWatersCategoryQuantity', total);
        localStorage.setItem('orderId', result.data.order_id);
        commit('setProductsFromBackEnd', result.data.order_items);
      }).catch((e) => {
        if (e.data.type === 1) {
          commit('clearCart');
        }
      });
    }
  },

  loadSupplierOrders({ commit }, callback = null) {
    commit('setSupplierOrders', []);
    if (this.$auth.loggedIn) {
      this.$axios.get('orders/supplier-orders').then((result) => {
        commit('setSupplierOrders', result.data.supplier_orders);
        if (callback !== null) {
          callback();
        }
        commit('setProductsFromBackEnd', result.data.order_items);
        if (result.data.order_items.length === 0) {
          this.$router.push(this._vm.$transformParamsForPort($nuxt._route, { name: 'catalog-slug', params: { slug: 'engineering' } }));
        }
      });
    } else {
      this.$router.push(this._vm.$transformParamsForPort($nuxt._route, { name: 'catalog-slug', params: { slug: 'engineering' } }));
    }
  },

  loadPorts({ commit }) {
    this.$axios.get(`locations/ports/autocomplete`).then((result) => {
      commit('setPorts', result.data);
    });
  },

  saveOrder({ state }, { cartItem }) {
    const orderId = localStorage.getItem('orderId');
    if (orderId) {
      this.$axios.$patch(`orders/${orderId}`, { products: state.products })
        .then(() => {
          globalBus.$emit('supplier_order:updated', cartItem?.supplierOrderId); // todo do without global bus
        })
        .catch((e) => {
          if (e.data.type === ERROR_TYPES.NOT_FOUND) {
            localStorage.removeItem('orderId');
            this.dispatch('cart/saveOrder');
          }
        });
    } else if (state.products.length) {
      this.$axios.post('orders', { products: state.products }).then((result) => {
        localStorage.setItem('orderId', result.data.id);
      });
    }
  },

  prepareOrderAfterLogin({ commit }) {
    localStorage.removeItem('orderId');
    this.$axios.get('orders/last-new').then((result) => {
      localStorage.setItem('orderId', result.data.order_id);
      commit('setProductsFromBackEnd', result.data.order_items);
    });
  },

  updateSupplierOrders({ state }) {
    if (this.$auth.loggedIn) {
      const data = { supplier_orders: state.supplierOrders, selected_address: state.selectedAddress };
      this.$axios.post('orders/update-supplier-orders', data).then(() => {
        this.$router.push(this._vm.$transformParamsForPort($nuxt._route, { path: '/checkout/overview' }));
      });
    } else {
      this.$router.push(this._vm.$transformParamsForPort($nuxt._route, { name: 'catalog-slug', params: { slug: 'engineering' } }));
    }
  },

  loadSupplierOrderDeliveryInfo({ commit }, supplierOrder) {
    if (!supplierOrder.address_data.location && !supplierOrder.pick_up_location_data) return;
    commit('shared/startLoading', null, { root: true });
    commit('setSupplierOrderData', { supplierOrder: supplierOrder, data: { promotion_data: null } });
    commit('setDeliveryInfo', { supplierOrder, deliveryInfo: { isLoading: true } });
    this.$axios.put('orders/delivery-info', {
      supplier_order_id: supplierOrder.id,
      address_data: supplierOrder.address_data,
      pick_up_location_data: supplierOrder.pick_up_location_data
    }).then(function (result) {
      if (supplierOrder.delivery_type) {
        commit('setSupplierOrderData', {
          supplierOrder: supplierOrder,
          data: {
            estimated_delivery_info: result.data.info[supplierOrder.delivery_type]
          }
        });
      }
      commit('setSupplierOrderData', { supplierOrder: supplierOrder, data: { delivery_by_type: result.data.type } });
      commit('setDeliveryInfo', { supplierOrder, deliveryInfo: result.data.info });
      if (!supplierOrder.delivery_type || !Object.keys(result.data.info).includes(supplierOrder.delivery_type + '')) {
        const deliveryType = Object.keys(result.data.info)[0];
        commit('setSupplierOrderData', { supplierOrder: supplierOrder, data: { delivery_type: deliveryType } });
      }
    }).catch(function (error) {
      console.error(error.data.error);
      commit('setDeliveryInfo', {
        supplierOrder,
        deliveryInfo: { hasError: true, errorType: error.data.type, errorCode: error.data.code, errorMessage: error.data.error }
      });
    })
      .finally(() => {
        commit('shared/endLoading', null, { root: true });
        this.dispatch('cart/loadDiscountInfo');
      });
  },

  updateSupplierOrderStatuses({ commit, state }, status) {
    const orderId = localStorage.getItem('orderId');
    if (orderId) {
      this.$axios.post(`orders/${orderId}/update-supplier-order-status`, { status: status });
    }
  },

  loadDiscountInfo({ commit, state }) {
    const orderId = localStorage.getItem('orderId');
    if (orderId) {
      const orderData = { delivery_info: state.deliveryInfo, supplier_orders: state.supplierOrders };
      commit('shared/startLoading', null, { root: true });
      this.$axios.put(`orders/${orderId}/discount-info`, orderData).then((result) => {
        state.supplierOrders.forEach((supplierOrder) => {
          let promotionData = {}
          if (result.data[supplierOrder.id]) {
            promotionData = result.data[supplierOrder.id];
          }
          commit('setSupplierOrderData', { supplierOrder: supplierOrder, data: { promotion_data: promotionData } });
        })
      }).finally(() => {
        commit('shared/endLoading', null, { root: true });
      });
    }
  },
  async showStripeButton({ commit }) {
    const reviewdata = await this.$axios.get('products/get_stripe_status');
    commit('setStripeStatus', reviewdata.data.status)
  },

  validateCoupon({ commit, state }, coupon_code) {
    const orderId = localStorage.getItem('orderId');
    if (orderId) {
      const localData = localStorage.getItem('deliveryInfo');
      const data = JSON.parse(localData);
      let delivery_info = Object.keys(state.deliveryInfo).length ? state.deliveryInfo : data;
      const orderData = { id: orderId, coupon_code };
      commit('shared/startLoading', null, { root: true });
      let exchange_rates;
      this.$axios.$get('products/get_exchange_rates').then((res) => {
        exchange_rates = res.find((el) => el.from_currency === 'EUR' && el.to_currency === 'USD').rate
        commit('update_exchange_rate_EUR_to_USD', exchange_rates);
      }).catch((error) => {
        console.log("failed to fetch exchange_rates", error)
      });
      this.$axios.put(`/orders/${orderId}/validate-coupon`, orderData).then((result) => {
        const res = result.data;
        if (res.error) {
          switch (res.error.message) {
            case "Resource not found":
              commit('updateVoucherError', res.reason);
              break;
            default:
              commit('updateVoucherError', res.error.message);
              break;
          }
          return;
        }
        if (res.valid) {
          let discount = res.discount;
          if (res.order && res.order.total_discount_amount) {
            discount = {
              ...discount,
              total_discount_amount: res.order.total_discount_amount
            }
          }
          if (discount) commit('updateVoucherDiscount', discount);
          commit('updateVoucherError', false);
          commit('updateAppliedCoupon', coupon_code);
        } else {
          commit('updateVoucherError', true);
          commit('updateVoucherDiscount', {});
        }
      }).catch((error) => {
        console.log("error while fetching coujpon info", error);
        commit('updateVoucherDiscount', {});
      }).finally(() => {
        commit('shared/endLoading', null, { root: true });
      });
    }
  },

  rollbackCoupon({ commit, state }) {
    const orderId = localStorage.getItem('orderId');
    if (orderId) {
      this.$axios.put(`orders/${orderId}/rollback-voucher-request`, { id: orderId }).then((res) => {
        console.log("Coupon rollback successful");
      }).catch((error) => {
        console.warn("There was some error while coupon rollback", error);
      });
    }
  },

  saveNewAddress({ commit, state }, { address, action_service }) {
    commit('shared/startLoading', null, { root: true });
    let payload = {
      shipping_address_data: address
    }
    if (action_service == 'edit') payload['action_service'] = 'edit';
    if (action_service == 'delete') payload['action_service'] = 'delete';
    this.$axios.post('orders/save-shipping-addresses', payload).then(() => {
      $nuxt.$store.commit('modal/setComponent', POPUP_NAMES.SAVED_ADDRESS)
    }).catch((error) => {
      if (error?.data?.error) commit('updateAddNewAddressError', error?.data?.error);
      console.error("Error while saving address.", error);
    }).finally(() => {
      commit('shared/endLoading', null, { root: true });
    });
  },

  getSavedAddresses({ commit, state }) {
    commit('shared/startLoading', null, { root: true });
    this.$axios.get('/orders/get-user-shipping-addresses').then((res) => {
      if (res.data) {
        commit('updateAddressList', res.data);
      }
    }).catch((error) => {
      console.warn("There was some error while fetching saved addresses", error);
    }).finally(() => {
      commit('shared/endLoading', null, { root: true });
    });
  },

  saveSelectedAddress({ commit, state }, payload) {
    this.$axios.put('/orders/save-selected-address', payload).then(() => {
      console.log("Successfully updated selected address");
    }).catch(error => {
      console.warn("There was some issue while updating selected address", error);
    });
  },

  updateSupplierOrderAddress({ commit, state }, payload) {
    this.$axios.put('/orders/save-address-for-supplier-order', payload).then(() => {
      console.log("Supplier order address update successful");
    }).catch((error) => {
      console.warn("Error while updating address in supplier order.", error);
    });
  }
};
