export const state = () => ({
  activeModal: null,
  modalData: null
});

export const mutations = {
  setComponent(state, component) {
    state.activeModal = component;
    document.body.classList.add('body--popup');
  },
  setModalData(state, modalData) {
    state.modalData = modalData;
  },
  unsetComponent(state) {
    state.activeModal = null;
    state.modalData = null;
    document.body.classList.remove('body--popup');
  }
};
