export const state = () => ({
  orders: []
});

export const mutations = {
  setOrders(state, orders) {
    state.orders = orders;
  }
};

export const actions = {
  loadOrders({ commit }) {
    this.$axios.$get('orders/user-profile-orders').then((orders) => {
      commit('setOrders', orders);
    })
    .catch(e => {
      this.error = e.data.error;
    });
  }
};
