export default function ({ $axios, app, store, route }) {
  $axios.onError(error => {
    throw error.response;
  });
  // Interceptor to include language parameter in requests
  $axios.onRequest(config => {
    const routes = route;
    const isLoggedIn = app.$auth.loggedIn;
    const orderApiPattern = /^\/orders\/\d+$/;
    if (orderApiPattern.test(config.url) && !isLoggedIn && routes.name === 'index') {
      // Do not send the request to this endpoint
      return Promise.reject('Request to session/user endpoint is blocked');
    }
    // Get the current language from the store or cookies
    const locale = app.$cookies.get('siteStateLang') ? app.$cookies.get('siteStateLang') : 'en';
    const country = app.$cookies.get('selectedCountry') ? app.$cookies.get('selectedCountry') : 'Monaco';
    const country_preference = app.$cookies.get('country_preference') ? app.$cookies.get('country_preference') : false;
    const country_flag = app.$cookies.get('siteFlag') ? app.$cookies.get('siteFlag') : 'https://flagcdn.com/mc.svg';
    const currency = app.$cookies.get('currency') ? app.$cookies.get('currency') : 'eur';
    // Initialize params object if it doesn't exist
    config.params = config.params || {};
    // Set the 'lang' parameter with the current language
    config.params.currency = currency;
    config.params.lang = locale;
    config.params.country = country;
    config.params.country_preference = country_preference;
    config.params.country_flag = country_flag;
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
  });
}