import { TYPES } from '@/utils/types/sort-type-catalog';

export const state = () => ({
  products: [],
  productsCount: 0,
  supplier: null,
});

export const mutations = {
  setProducts(state, products) {
    state.products = products;
  },
  setProductsCount(state, productsCount) {
    state.productsCount = productsCount;
  },
  setSupplier(state, supplier) {
    state.supplier = supplier;
  },
};

export const getters =  {
  products(state) {
    return state.products;
  },
  productsCount(state) {
    return state.productsCount;
  },
  supplier(state) {
    return state.supplier;
  },
};

export const actions = {
  async fetchSupplier({ commit }, { slug }) {
    const supplier = await this.$axios.$get(`suppliers/${slug}`);
    commit('setSupplier', supplier);
    return supplier;
  },
  async fetchSuppliersProducts({ commit, rootGetters }, { supplierName, page, sort }) {
    const query = { is_usa_prices: rootGetters['shared/isRegionUS'], seller: supplierName }
    const searchParams = { page, sort, query };
    const productsData = await this.$axios.$post('products/filter_seller', searchParams);
    commit('setProducts', productsData.products);
    commit('setProductsCount', productsData.products_count);
  }
};
